let appEnv = "";
let s3region = "";
let s3rootfolder = "";
let s3accelerated = "";
let s3envfolder = "";
//var state_machine_arn = "";
let endpoints = {};
let userGuideLink = "";
let isLocalHost = window.location.hostname === "localhost";

let pipeLineData = JSON.parse(localStorage.getItem('pipelineData'));

// pick the first region since all regions are identical at the moment .. if the regions are different per pipeline then this logic will change

// this ternary logic will make sure that the app does not fail when the pipeline data is null - this is because when you start the app - app.congif runs before login and pipeline data is not yet available
//var region = pipeLineData ? pipeLineData.data?.data?.[0].awsRegion : "";
let region = "us-west-2";
let msalConfig = {};
let wsFileCopyStatusLink = "";

switch (window.location.hostname) {
  case "seqsense.bio-rad.com":
  case "d2p3swwhwolaw7.cloudfront.net":
    appEnv = "prod";
    s3region = region;
    s3rootfolder = `dbg-cloudpipeline-data-${s3region}-${appEnv}`;
    s3accelerated = `dbg-cloudpipeline-data-${s3region}-${appEnv}.s3-accelerate.amazonaws.com`;
    s3envfolder = "jobs";
    endpoints = {
      s3bucketroot: `https://s3.${s3region}.amazonaws.com`,
      s3bucket: `https://s3.${s3region}.amazonaws.com/${s3rootfolder}`,
      s3bucketaccelerated: `https://s3.${s3region}.amazonaws.com/${s3accelerated}`,
      main: "https://217mj56sj2.execute-api.us-west-2.amazonaws.com/prod"
    };

    userGuideLink =
      "https://drive.google.com/file/d/17RNtG8u1qgVpWj1wKC1Zz2tt2r_0FANp/view?usp=sharing";
    wsFileCopyStatusLink = "wss://x0fuf8y5og.execute-api.us-west-2.amazonaws.com/production/";
    msalConfig = {
      auth: {
        clientId: 'af02e9d4-e804-48dc-82e1-9be3e1b094a5',
        authority: 'https://login.bio-rad.com/login.bio-rad.com/B2C_1A_SUSI_USERNAME',
        knownAuthorities: ["login.bio-rad.com"],
        redirectUri: window.location.origin + "/home",
        postLogoutRedirectUri: window.location.origin,
        //  navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false,
        secureCookies: false
      },
      system: {
        loggerOptions: {
          loggerCallback(logLevel, message, containsPii) {
            if (containsPii) {
              return;
            }
            switch (logLevel) {
              case logLevel.Error:
                console.error(message);
                return;
              case logLevel.Info:
                console.info(message);
                return;
              case logLevel.Verbose:
                console.debug(message);
                return;
              case logLevel.Warning:
                console.warn(message);
                return;
            }
          },
          piiLoggingEnabled: false,
          // logLevel: LogLevel.Verbose,
        },

        misc: {
          tenantId: 'b9aa89b6-fc1d-4161-afcc-655f9df069f6',
          domainName: 'login.bio-rad.com',
          policy: 'b2c_1a_susi_username'
        }
      },
    };
    break;

  case "d3a4pibimi08mv.cloudfront.net":
  case "eu-seqsense.bio-rad.com":
    appEnv = "prod";
    s3region = "eu-west-1";
    s3rootfolder = `dbg-cloudpipeline-data-${s3region}-${appEnv}`;
    s3accelerated = `dbg-cloudpipeline-data-${s3region}-${appEnv}.s3-accelerate.amazonaws.com`;
    s3envfolder = "jobs";
    endpoints = {
      s3bucketroot: `https://s3.${s3region}.amazonaws.com`,
      s3bucket: `https://s3.${s3region}.amazonaws.com/${s3rootfolder}`,
      s3bucketaccelerated: `https://s3.${s3region}.amazonaws.com/${s3accelerated}`,
      main: "https://8c4umxpby5.execute-api.eu-west-1.amazonaws.com/prod"
    };
    userGuideLink =
      "https://drive.google.com/file/d/17RNtG8u1qgVpWj1wKC1Zz2tt2r_0FANp/view?usp=sharing";
    wsFileCopyStatusLink = "wss://9k680a5st8.execute-api.eu-west-1.amazonaws.com/production/";
    msalConfig = {
      auth: {
        clientId: 'af02e9d4-e804-48dc-82e1-9be3e1b094a5',
        authority: 'https://login.bio-rad.com/login.bio-rad.com/B2C_1A_SUSI_USERNAME',
        knownAuthorities: ["login.bio-rad.com"],
        redirectUri: window.location.origin + "/home",
        postLogoutRedirectUri: window.location.origin,
        //  navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false,
        secureCookies: false
      },
      system: {
        loggerOptions: {
          loggerCallback(logLevel, message, containsPii) {
            if (containsPii) {
              return;
            }
            switch (logLevel) {
              case logLevel.Error:
                console.error(message);
                return;
              case logLevel.Info:
                console.info(message);
                return;
              case logLevel.Verbose:
                console.debug(message);
                return;
              case logLevel.Warning:
                console.warn(message);
                return;
            }
          },
          piiLoggingEnabled: false,
          // logLevel: LogLevel.Verbose,
        },

        misc: {
          tenantId: 'b9aa89b6-fc1d-4161-afcc-655f9df069f6',
          domainName: 'login.bio-rad.com',
          policy: 'b2c_1a_susi_username'
        }
      },
    };
    break;

  case "d353hnr8398bo6.cloudfront.net":
    appEnv = "test";
    s3region = region;
    s3rootfolder = `dbg-cloudpipeline-data-${s3region}-${appEnv}`;
    s3accelerated = `dbg-cloudpipeline-data-${s3region}-${appEnv}.s3-accelerate.amazonaws.com`;
    s3envfolder = "jobs";
    endpoints = {
      s3bucketroot: `https://s3.${s3region}.amazonaws.com`,
      s3bucket: `https://s3.${s3region}.amazonaws.com/${s3rootfolder}`,
      s3bucketaccelerated: `https://s3.${s3region}.amazonaws.com/${s3accelerated}`,
      main: "https://u1574u0b7d.execute-api.us-west-2.amazonaws.com/test"
    };
    userGuideLink =
      "https://drive.google.com/file/d/17RNtG8u1qgVpWj1wKC1Zz2tt2r_0FANp/view?usp=sharing";
    wsFileCopyStatusLink = "wss://y5umyzchv5.execute-api.us-west-2.amazonaws.com/test/";
    msalConfig = {
      auth: {
        clientId: '2099e965-7801-43a1-a951-08f15aa39a88',
        authority: 'https://login-idev1.bio-rad.com/login-idev1.bio-rad.com/B2C_1A_SUSI_USERNAME',
        knownAuthorities: ["login-idev1.bio-rad.com"],
        redirectUri: window.location.origin + "/home",
        postLogoutRedirectUri: window.location.origin,
        //  navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false,
        secureCookies: false
      },
      system: {
        loggerOptions: {
          loggerCallback(logLevel, message, containsPii) {
            if (containsPii) {
              return;
            }
            switch (logLevel) {
              case logLevel.Error:
                console.error(message);
                return;
              case logLevel.Info:
                console.info(message);
                return;
              case logLevel.Verbose:
                console.debug(message);
                return;
              case logLevel.Warning:
                console.warn(message);
                return;
            }
          },
          piiLoggingEnabled: false,
          // logLevel: LogLevel.Verbose,
        },

        misc: {
          tenantId: 'a77597d4-ce1c-4423-bd14-0faa2b544557',
          domainName: 'login-idev1.bio-rad.com',
          policy: 'b2c_1a_susi_username'
        }
      },
    };

    break;

  case "d3cjypblvi86en.cloudfront.net":
    appEnv = "test";
    s3region = "eu-west-1";
    s3rootfolder = `dbg-cloudpipeline-data-${s3region}-${appEnv}`;
    s3accelerated = `dbg-cloudpipeline-data-${s3region}-${appEnv}.s3-accelerate.amazonaws.com`;
    s3envfolder = "jobs";
    endpoints = {
      s3bucketroot: `https://s3.${s3region}.amazonaws.com`,
      s3bucket: `https://s3.${s3region}.amazonaws.com/${s3rootfolder}`,
      s3bucketaccelerated: `https://s3.${s3region}.amazonaws.com/${s3accelerated}`,
      main: "https://u01jlp25dc.execute-api.eu-west-1.amazonaws.com/test"
    };
    userGuideLink =
      "https://drive.google.com/file/d/17RNtG8u1qgVpWj1wKC1Zz2tt2r_0FANp/view?usp=sharing";
    wsFileCopyStatusLink = "wss://nmz512s39i.execute-api.eu-west-1.amazonaws.com/test/";
    msalConfig = {
      auth: {
        clientId: '2099e965-7801-43a1-a951-08f15aa39a88',
        authority: 'https://login-idev1.bio-rad.com/login-idev1.bio-rad.com/B2C_1A_SUSI_USERNAME',
        knownAuthorities: ["login-idev1.bio-rad.com"],
        redirectUri: window.location.origin + "/home",
        postLogoutRedirectUri: window.location.origin,
        //  navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false,
        secureCookies: false
      },
      system: {
        loggerOptions: {
          loggerCallback(logLevel, message, containsPii) {
            if (containsPii) {
              return;
            }
            switch (logLevel) {
              case logLevel.Error:
                console.error(message);
                return;
              case logLevel.Info:
                console.info(message);
                return;
              case logLevel.Verbose:
                console.debug(message);
                return;
              case logLevel.Warning:
                console.warn(message);
                return;
            }
          },
          piiLoggingEnabled: false,
          // logLevel: LogLevel.Verbose,
        },

        misc: {
          tenantId: 'a77597d4-ce1c-4423-bd14-0faa2b544557',
          domainName: 'login-idev1.bio-rad.com',
          policy: 'b2c_1a_susi_username'
        }
      },
    };

    break;

  case "d1j3p1mv9n7rc9.cloudfront.net":
    appEnv = "dev";
    s3region = "eu-west-1";
    s3rootfolder = `dbg-cloudpipeline-data-${s3region}-${appEnv}`;
    s3accelerated = `dbg-cloudpipeline-data-${s3region}-${appEnv}.s3-accelerate.amazonaws.com`;
    s3envfolder = "jobs";
    endpoints = {
      s3bucketroot: `https://s3.${s3region}.amazonaws.com`,
      s3bucket: `https://s3.${s3region}.amazonaws.com/${s3rootfolder}`,
      s3bucketaccelerated: `https://s3.${s3region}.amazonaws.com/${s3accelerated}`,
      main: `https://008jsahwb0.execute-api.eu-west-1.amazonaws.com/dev`
    };
    userGuideLink =
      "https://drive.google.com/file/d/17RNtG8u1qgVpWj1wKC1Zz2tt2r_0FANp/view?usp=sharing";
    wsFileCopyStatusLink = "wss://q9onef1a26.execute-api.eu-west-1.amazonaws.com/dev/"

    msalConfig = {
      auth: {
        clientId: '37a4e31c-b542-49e7-890d-75615c449f9f',
        authority: 'https://login-dev1.bio-rad.com/login-dev1.bio-rad.com/B2C_1A_SUSI_USERNAME',
        knownAuthorities: ["login-dev1.bio-rad.com"],
        redirectUri: window.location.origin + "/home",
        postLogoutRedirectUri: window.location.origin,
        //  navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false,
        secureCookies: false
      },
      system: {
        loggerOptions: {
          loggerCallback(logLevel, message, containsPii) {
            if (containsPii) {
              return;
            }
            switch (logLevel) {
              case logLevel.Error:
                console.error(message);
                return;
              case logLevel.Info:
                console.info(message);
                return;
              case logLevel.Verbose:
                console.debug(message);
                return;
              case logLevel.Warning:
                console.warn(message);
                return;
            }
          },
          piiLoggingEnabled: false,
          // logLevel: LogLevel.Verbose,
        },

        misc: {
          tenantId: 'ec986a54-728a-4271-83ea-f1bb89134379',
          domainName: 'login-dev1.bio-rad.com',
          policy: 'b2c_1a_susi_username'
        }
      },
    };

    break;

  // US d2dmm7qght2e3m.cloudfront.net
  default:
    appEnv = "dev";
    s3region = region;
    s3rootfolder = "dbg-cloudpipeline-data-us-west-2-dev";
    s3accelerated = `dbg-cloudpipeline-data-${s3region}-${appEnv}.s3-accelerate.amazonaws.com`;
    s3envfolder = "jobs";
    endpoints = {
      s3bucketroot: `https://s3.${s3region}.amazonaws.com`,
      s3bucket: `https://s3.${s3region}.amazonaws.com/${s3rootfolder}`,
      s3bucketaccelerated: `https://s3.${s3region}.amazonaws.com/${s3accelerated}`,
      main: isLocalHost
        ? "https://i742coix41.execute-api.us-west-2.amazonaws.com/dev" // change to locahost when you want to use local DB
        : "https://i742coix41.execute-api.us-west-2.amazonaws.com/dev"
    };
    userGuideLink =
      "https://drive.google.com/file/d/17RNtG8u1qgVpWj1wKC1Zz2tt2r_0FANp/view?usp=sharing";
    wsFileCopyStatusLink = "wss://0puv44rtt7.execute-api.us-west-2.amazonaws.com/dev/"
    msalConfig = {
      auth: {
        clientId: '37a4e31c-b542-49e7-890d-75615c449f9f',
        authority: 'https://login-dev1.bio-rad.com/login-dev1.bio-rad.com/B2C_1A_SUSI_USERNAME',
        knownAuthorities: ["login-dev1.bio-rad.com"],
        redirectUri: window.location.origin + "/home",
        postLogoutRedirectUri: window.location.origin,
        //  navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false,
        secureCookies: false
      },
      system: {
        loggerOptions: {
          loggerCallback(logLevel, message, containsPii) {
            if (containsPii) {
              return;
            }
            switch (logLevel) {
              case logLevel.Error:
                console.error(message);
                return;
              case logLevel.Info:
                console.info(message);
                return;
              case logLevel.Verbose:
                console.debug(message);
                return;
              case logLevel.Warning:
                console.warn(message);
                return;
            }
          },
          piiLoggingEnabled: false,
          // logLevel: LogLevel.Verbose,
        },

        misc: {
          tenantId: 'ec986a54-728a-4271-83ea-f1bb89134379',
          domainName: 'login-dev1.bio-rad.com',
          policy: 'b2c_1a_susi_username'
        }
      },
    };
}

export const APPENV = appEnv;
export const S3REGION = s3region;
export const S3ROOTFOLDER = s3rootfolder;
export const S3ENVFOLDER = s3envfolder;
export const S3ACCELERATED = s3accelerated;
//export const STATE_MACHINE_ARN = state_machine_arn;
export const USER_GUIDE_LINK = userGuideLink;
export const PIPELINEDATA = pipeLineData;
export const VERSION = "2";
export const WS_FILE_COPY_STATUS_LINK = wsFileCopyStatusLink;

export const ENDPOINTS = {
  s3BucketRoot: endpoints["s3bucketroot"],
  s3Bucket: endpoints["s3bucket"],
  s3BucketAccelerated: endpoints["s3bucketaccelerated"],
  executeWorkflow: endpoints["executeworkflow"],
  status: endpoints["status"],
  signedUrl: endpoints["signedurl"],
  main: endpoints["main"]
};

export const MSAL_CONFIG = msalConfig;

export const loginRequest = {
  scopes: ["openid", "profile", "email"],
  // prompt: 'select_account',
  // pkce: true,
};

export default {};
