import axios from 'axios';
import * as config from "../../app.config";
import { setHeaders } from "../../utils/basic";

export async function signUrl(partName, blob, experimentId, pipelineId) {

    const postData = {
        bucket_name: config.S3ROOTFOLDER,
        region: config.S3REGION,
        expiration: 3600,
        pipelineId: pipelineId,
        file_name: partName,
        //endpoint_url: config.ENDPOINTS.s3Bucket, 
        // using the accelerated s3 bucket for faster upload for large files, as mentioned in the app.config.js file. 
        endpoint_url: config.ENDPOINTS.s3BucketAccelerated,
        file_directory: `${config.S3ENVFOLDER}/${experimentId}/inputs`
    };

    return await axios
        .post(`${config.ENDPOINTS.main}/signUrl`, postData, {
            headers: setHeaders()
        })
        .then(resp => {
            let output = resp.data;
            output.blob = blob;
            output.partName = partName;
            output.experimentId = experimentId;
            output.status = 'success';
            return output;
        })
        .catch(error => {
            const errObj = {};
            errObj.data = error.response.data;
            errObj.status = error.response.status;
            return errObj; /// add user message . this is done
        });
}
